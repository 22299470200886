import { IGNORE_COLUMN } from "./constants";

export function getUpdatedDisableOptionArr(
  disableOptionArr: boolean[],
  newSelectedCol: string,
  oldSelectedCol: string,
  keyOptions: any
): boolean[] {
  const copyDisableOptionArr = [...disableOptionArr];

  if (newSelectedCol !== IGNORE_COLUMN) {
    copyDisableOptionArr[keyOptions.indexOf(newSelectedCol)] = true;
  }
  copyDisableOptionArr[keyOptions.indexOf(oldSelectedCol)] = false;

  return copyDisableOptionArr;
}
