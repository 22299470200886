import { ContactMappingType } from "./types";

export const ContactsMapping: ContactMappingType = {
  "First name": "first_name",
  "Last name": "last_name",
  "Phone number": "phone_number",
  "Email address": "email",
  "Street address": "address",
  City: "city",
  State: "state",
  "ZIP code": "zipcode",
  Notes: "notes",
};
export const IGNORE_COLUMN = "Leave blank";
