import { ArrowRightIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";
import GenericDropdown from "../../../common/GenericDropdown/GenericDropdown";
import { ContactsMapping, IGNORE_COLUMN } from "./constants";
import ExampleText from "./ExampleText";
import { getUpdatedDisableOptionArr } from "./utils";

interface CsvHeaderDropdownsProps {
  headerKeys: any[];
  array: any[];
  csvTitle: any;
  dataMap: any;
  setDataMap: any;
}

export default function CsvHeaderDropdowns({
  headerKeys,
  array,
  csvTitle,
  dataMap,
  setDataMap,
}: CsvHeaderDropdownsProps) {
  // mapping potential col on mount
  useEffect(() => {
    if (headerKeys) {
      setDisableOptionArr(Object.keys(headerKeys).map(() => false));
      getPotentialMatchingColName();
      // headerKeys?.map((key, idx) => getPotentialMatchingColName(key, idx));
    }
  }, [headerKeys?.length]);

  function constructDropdownOptionsFromHeaderKeys(
    headerKeys: any,
    idx: number
  ) {
    let optionArr: any = [];
    for (const key of headerKeys) {
      optionArr = [
        ...optionArr,
        {
          text: key,
          onClick: () =>
            handleSetCsvHeaderColumns(key, idx, getSelected(headerKeys, idx)),
        },
      ];
    }

    return optionArr;
  }

  const toplineProVars = Object.values(ContactsMapping);
  const toplineProColNames = Object.keys(ContactsMapping);

  const [disableOptionArr, setDisableOptionArr] = useState<boolean[]>([]);

  let copyHeaderKeys: any = [];

  useEffect(() => {
    const keyArr = Object.values(dataMap);
  }, [dataMap]);

  function getPotentialMatchingColName(): void {
    copyHeaderKeys = [...headerKeys];

    const mappedData = {};

    for (let i = 0; i < toplineProColNames.length; i++) {
      const toplineProColName = toplineProVars[i];
      const matchColumnName = "";

      for (let j = 0; j < headerKeys.length; j++) {
        const csvColName = headerKeys[j];

        if (
          (toplineProColName.toLowerCase().includes(csvColName.toLowerCase()) ||
            csvColName
              .toLowerCase()
              .includes(toplineProColName.toLocaleLowerCase())) &&
          !Object.values(mappedData).includes(csvColName)
        ) {
          mappedData[toplineProColName] = csvColName;
          const index = headerKeys.findIndex((header) => header === csvColName);
          disableOptionArr[index] = true;
          break;
        } else {
          mappedData[toplineProColName] = IGNORE_COLUMN;
        }
      }
    }
    setDisableOptionArr([...disableOptionArr]);
    setDataMap(mappedData);
  }

  function handleSetCsvHeaderColumns(
    newColName: string,
    idx: number,
    oldColName: string
  ) {
    const newCsvHeaderColumns = [...headerKeys];
    newCsvHeaderColumns[idx] = newColName;

    const newMap = { ...dataMap };
    newMap[toplineProVars[idx]] = newColName;

    setDataMap(newMap);

    const copyDisableOptionArr = getUpdatedDisableOptionArr(
      disableOptionArr,
      newColName,
      oldColName,
      headerKeys
    );
    setDisableOptionArr(copyDisableOptionArr);
  }

  function getSelected(headerKey: any, idx: number) {
    const matchedHeaderKeysArr = Object.values(dataMap);
    const selected = headerKey.find(
      (header: string) => header === matchedHeaderKeysArr[idx]
    );
    return selected;
  }

  const isMobile = useIsMobile();

  return (
    <div className="-mx-6 -mt-7 sm:mt-0 bg-white">
      <p className="text-gray-500 py-4 sm:px-6 pr-4 pl-6 text-sm text-left">
        Map first name, last name, and phone number or email to upload contacts.
      </p>
      <div className="flex text-gray-700 text-xs-medium bg-gray-100 py-4 px-6 border-b border-t border-gray-200">
        <div
          className="line-clamp-1 text-left font-medium"
          style={{ width: isMobile ? "49%" : "240px" }}
        >
          .CSV COLUMN <span className="font-normal">({csvTitle})</span>
        </div>
        <div style={{ width: isMobile ? "11%" : "68px" }}></div>
        <div className="sm:mr-6" style={{ width: isMobile ? "40%" : "210px" }}>
          <p className="text-left whitespace-nowrap">TOPLINE PRO FIELD</p>
        </div>
        <div className="hidden sm:block" style={{ width: "210px" }}>
          <p className="text-left whitespace-nowrap">EXAMPLE</p>
        </div>
      </div>
      <div className="flex flex-grow sm:max-h-400 sm:overflow-auto">
        <div className="w-full">
          {toplineProColNames.map((key, idx) => (
            <div key={idx} className="flex sm:py-3.5 py-4 border-b px-6">
              <div
                className="flex flex-col items-center"
                style={{
                  width: isMobile ? "49%" : "240px",
                }}
              >
                <div className="w-full">
                  <GenericDropdown
                    size="lg"
                    displayedItem={{ text: getSelected(headerKeys, idx) }}
                    dropdownItems={constructDropdownOptionsFromHeaderKeys(
                      headerKeys,
                      idx
                    )}
                    menuButton={true}
                    dropDirection="bottom-start"
                    customStyle="sm:w-60 w-full"
                    disableArray={disableOptionArr}
                    showTrailingCheckIcon={true}
                  />
                </div>
                {getSelected(headerKeys, idx) !== IGNORE_COLUMN ? (
                  <div className="w-full flex gap-1 mt-1">
                    <CheckIcon className="h-4 w-4 text-green-600" />
                    <span className="text-gray-900 text-xs-normal">
                      Suggested match found
                    </span>
                  </div>
                ) : (
                  <div className="sm:hidden h-5"></div>
                )}
              </div>
              <div
                className={`flex items-center justify-center ${
                  getSelected(headerKeys, idx) !== IGNORE_COLUMN
                    ? "mb-5"
                    : "sm:mb-0 mb-5"
                }`}
                style={{ width: isMobile ? "11%" : "68px" }}
              >
                <ArrowRightIcon
                  key={idx.toString()}
                  className={`w-5 h-5 text-blue-600`}
                />
              </div>

              <div
                className={`flex sm:flex-row flex-col sm:items-center justify-between flex-wrap sm:mr-6 truncate ${
                  getSelected(headerKeys, idx) !== IGNORE_COLUMN && "sm:mb-5"
                }`}
                style={{ width: isMobile ? "40%" : "210px" }}
              >
                <div className="w-full text-left break-words text-gray-700 text-body-medium sm:mt-0 mt-2.5 align-text-top">
                  {key}
                </div>
                <div>
                  <span className="flex sm:hidden text-gray-700 font-normal align-text-bottom">
                    <ExampleText
                      text={array[0][getSelected(headerKeys, idx)]}
                    />
                  </span>
                </div>
              </div>
              <div
                className={`hidden sm:block flex items-center text-gray-700 text-body-normal ${
                  getSelected(headerKeys, idx) !== IGNORE_COLUMN && "mb-5"
                }`}
                style={{ width: "210px", maxWidth: "210px" }}
              >
                <ExampleText text={array[0][getSelected(headerKeys, idx)]} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
