import { useGoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { DesktopContext } from "../contexts/DesktopContextProvider/DesktopContextProvider";
import {
  getGmbTokens,
  GoogleToken,
  storeGoogleContactsTokens,
} from "../components/primaryrender/connections/ConnectionsTab/utils";
import { useRouter } from "next/router";
import { importGoogleContacts } from "../components/primaryrender/contacts/CsvUploadModal/utils";
import { importGoogleContactsToast } from "./utils";

export function useAuthGoogleContacts(slug: any) {
  const {
    setGoogleContactsAuthenticated,
    basicInfo,
    setContacts,
    setGoogleContactsLoading,
  } = useContext(DesktopContext);

  const router = useRouter();

  async function authenticateGoogleContacts(tokenObj: GoogleToken) {
    try {
      const accessToken = tokenObj?.access_token;
      if (!accessToken) return;

      await storeGoogleContactsTokens(tokenObj, slug);

      //pull in google contacts
      setGoogleContactsLoading(true);
      const contacts = await importGoogleContacts();

      const { data, google_contact_counter, deleted_contact_count } = contacts;
      importGoogleContactsToast({
        status: "success",
        google_contact_counter,
        deleted_contact_count,
      });
      setContacts(data);
      setGoogleContactsAuthenticated(true);
    } catch (e) {
      importGoogleContactsToast({ status: "failure" });
    } finally {
      setGoogleContactsLoading(false);
    }
  }

  const startGoogleContactsLogin = useGoogleLogin({
    onSuccess: (response) => gmbExchangeCodeForTokens(response),
    onError: () => console.log("Login Failed"),
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly",
  });

  async function gmbExchangeCodeForTokens(response: any) {
    const code = response?.code;
    const { sitePk } = basicInfo;
    const tokensObj = await getGmbTokens(code, sitePk);
    await authenticateGoogleContacts(tokensObj);
  }

  return {
    startGoogleContactsLogin,
  };
}
