import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../hooks/useIsMobile";

function ExampleText({ text }: any) {
  const [isTruncated, setIsTruncated] = useState(false);
  const isMobile = useIsMobile();
  const characterCounter = isMobile ? 12 : 23;

  useEffect(() => {
    if (text) setIsTruncated(text.length > characterCounter);
  }, [text]);

  return (
    <div className="h-full w-full flex items-center">
      {text &&
        `${isMobile ? "ex. " : ""}${text.slice(0, characterCounter)}${
          isTruncated ? "..." : ""
        }`}
      {!text && <div className="flex items-center ">--</div>}
    </div>
  );
}

export default ExampleText;
