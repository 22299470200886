import Image from "next/image";
import React from "react";
import { GOOGLE_CONTACTS, QUICKBOOKS } from "../CsvUploadModal/constants";
import { AnotherSourceProps } from "./types";
import quickbooks_logo_svg from "../../../../public//quickbooks_logo.svg";
import useIsMobile from "../../../../hooks/useIsMobile";

const AnotherSource = ({
  anotherSource,
  setAnotherSource,
}: AnotherSourceProps) => {
  const isMobile = useIsMobile();
  return (
    <>
      <div className="sm:text-left mb-4">
        <div className="text-h3-medium text-gray-900 mb-4 sm:mt-0 mt-4">
          From another source
        </div>
        <div className="w-full bg-gray-50 justify-start items-center sm:inline-flex gap-6 sm:space-y-0 space-y-4">
          <div
            className={`flex items-center bg-white border-gray-200 py-5 pl-5 rounded-md cursor-pointer ${
              anotherSource === QUICKBOOKS
                ? "border border-blue-500"
                : "border border-gray-200"
            }`}
            style={{ paddingRight: "62px" }}
            onClick={() => setAnotherSource(QUICKBOOKS)}
          >
            <input
              id="quickbooks"
              name="quickbooks"
              type="radio"
              checked={anotherSource === QUICKBOOKS}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 mr-4"
            />
            <label
              htmlFor="quickbooks"
              className="block cursor-pointer flex items-center py-1.5"
            >
              <Image
                layout={"intrinsic"}
                src={quickbooks_logo_svg}
                alt="quickbooks logo"
                objectFit="cover"
                // placeholder="blur"
                width="156px"
                height="30px"
              />
            </label>
          </div>
          <div
            className={`flex items-center p-5 bg-white rounded-md cursor-pointer ${
              anotherSource === GOOGLE_CONTACTS
                ? "border border-blue-500"
                : "border border-gray-200"
            }`}
            onClick={() => setAnotherSource(GOOGLE_CONTACTS)}
          >
            <input
              id="google-contacts"
              name="google-contacts"
              type="radio"
              checked={anotherSource === GOOGLE_CONTACTS}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600 mr-4"
            />
            <label
              htmlFor="google-contacts"
              style={{ height: "44px" }}
              className="cursor-pointer"
            >
              <div className="relative h-11" style={{ width: "202px" }}>
                <Image
                  layout={"fill"}
                  src="/google-contacts-logo.svg"
                  alt="logo"
                  className="h-11"
                  width="202px"
                />
              </div>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnotherSource;
