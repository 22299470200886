export const extractFileName = (
  filePath: string | undefined
): string | undefined => {
  if (!filePath) return undefined;
  const parts = filePath.split("C:\\fakepath\\");
  return parts.length > 1 ? parts[1] : undefined;
};
export function filterCsvFiles(files: FileList): File[] {
  return Array.from(files).filter((file) => file.type === "text/csv");
}
