import React, { useRef, useState } from "react";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import { MAXIMUM_FILE_UPLOAD_SIZE_BYTES } from "../CsvUploadModal/constants";
import AddPhotoIcon from "../../../common/Icons/AddPhotoIcon/AddPhotoIcon";
import { extractFileName, filterCsvFiles } from "./utils";
import { ContactModalFileUploadProps } from "./types";

const ContactModalFileUpload = ({
  csvTitle,
  setAlert,
  setFile,
  setCsvTitle,
  setAnotherSource,
}: ContactModalFileUploadProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [maximumFileSizeError, setMaximumFileSizeError] =
    useState<boolean>(false);
  const inputClick = () => {
    setAnotherSource("");
    if (null !== inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAlert("");
    const file = e.target.files?.[0];

    if (file && isValidFileUploadSize(file.size)) {
      setFile(file);
      const fileName = extractFileName(e.target.value);
      if (fileName) {
        setCsvTitle(fileName);
      }
    }
  };

  const isValidFileUploadSize = (fileSizeInBytes: number): boolean => {
    if (fileSizeInBytes <= MAXIMUM_FILE_UPLOAD_SIZE_BYTES) {
      setMaximumFileSizeError(false);
      return true;
    }
    setMaximumFileSizeError(true);
    setFile(null);
    setCsvTitle(null);
    return false;
  };

  function handleValidFile(file: File): void {
    if (isValidFileUploadSize(file.size)) {
      setFile(file);
      setCsvTitle(file.name);
    }
  }

  function dropHandler(e: React.DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    const csvFiles = filterCsvFiles(e.dataTransfer.files);
    csvFiles.forEach(handleValidFile);
  }

  function dragOverHandler(e: any) {
    e.preventDefault();
  }

  return (
    <div className="flex justify-center h-full w-full mb-4">
      <div
        className={`${
          !csvTitle && "hidden"
        } transition-all duration-500 ease-in-out flex flex-col items-center`}
      >
        <p className="break-words">{csvTitle}</p>
        <SecondaryButton
          onClickFunc={inputClick}
          text="Change"
          size="md"
          customStyle="mt-2"
        />
      </div>
      <div
        className={`${
          csvTitle && "hidden"
        } flex flex-grow h-full transition-all duration-500 ease-in-out space-y-1 text-center`}
      >
        <div
          ref={dropZoneRef}
          onDrop={(event) => dropHandler(event)}
          onDragOver={(event) => dragOverHandler(event)}
          onClick={inputClick}
          className="flex flex-col w-full items-center justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
        >
          <AddPhotoIcon />
          <div
            className="flex text-sm-normal text-gray-600"
            onClick={() => setAlert("")}
          >
            <label
              htmlFor="file-upload"
              className="relative pointer-events-none cursor-pointer rounded-md text-body-normal focus-within:outline-none focus:outline-none"
            >
              <span className="text-blue-600 mr-1" data-cy="upload-a-file">
                Upload a file
              </span>
              or drag and drop
              <br />
              <span className="text-gray-400 text-sm-normal mt-2">
                .csv or .vcf files only (3 MB MAX)
              </span>
              {maximumFileSizeError && (
                <div className="text-red-600 text-sm-normal mt-2">
                  File exceeds 3 MB.
                </div>
              )}
            </label>
            <input
              id="file-upload"
              name="file-upload"
              ref={inputFile}
              type="file"
              accept=".csv, .vcf"
              onChange={(event) => handleOnChange(event)}
              className="sr-only"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModalFileUpload;
