import { useContext, useEffect, useState } from "react";
import { IContact } from "../../components/primaryrender/contacts/Contact/types";
import { useRouter } from "next/router";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import { BLANK_CONTACT } from "../../components/primaryrender/contacts/ContactsPage/constants";
import { toast } from "react-toastify";
import { IHandleSaveNewContactReturn } from "../../components/primaryrender/contacts/ContactsPage/types";
import saveNewContact from "../../components/primaryrender/contacts/helpers/saveNewContact";
import CsvUploadModal from "../../components/primaryrender/contacts/CsvUploadModal/CsvUploadModal";

export function useContacts() {
  const [activeContact, setActiveContact] = useState<IContact | null>();
  const [showDropdownFilter, setShowDropdownFilter] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const [newContactData, setNewContactData] = useState<IContact>();
  const [csvUploadModalOpen, setCsvUploadModalOpen] = useState(false);

  const router = useRouter();
  const { profileInfo, contacts, setContacts } = useContext(DesktopContext);
  const { slug } = profileInfo || {};

  useEffect(() => {
    if (router?.query?.viewContact) {
      const id = router.query.viewContact as string;
      if (id) {
        const contactId = Number(id);
        const contact = contacts.find((c: IContact) => c.id === contactId);
        if (contact) {
          setActiveContact(contact);
          handleSetContactSlideInOpen(true);
        }
      }
    }
  }, [router?.query?.viewContact]);

  function handleSetContactSlideInOpen(view: boolean, addNewContact?: boolean) {
    setShowDropdownFilter(false);
    if (!view) setEditing(true);
    setAddContactModalOpen(true);
    if (addNewContact) {
      setNewContactData(BLANK_CONTACT);
    }
  }
  async function handleSaveNewContact(
    contactInfoArr: IContact[]
  ): Promise<IHandleSaveNewContactReturn | string> {
    const response = await saveNewContact(slug, contactInfoArr);
    const newContactList = response.contact_list;
    const emptyContactCount = response.empty_contact_count;
    if (newContactList === "error") {
      setActiveContact(null);
      toast.error("Error adding contact. Please try again.");
      return "error";
    }
    const newContacts = [...contacts, ...newContactList];
    setContacts(newContacts);
    setActiveContact(null);

    toast.success(`Contact${contactInfoArr?.length > 0 ? "s" : ""} added`);

    return {
      imported: newContactList.length,
      duplicated:
        contactInfoArr.length - emptyContactCount - newContactList.length,
    };
  }

  function renderCsvModal() {
    return (
      <CsvUploadModal
        open={csvUploadModalOpen}
        setOpen={setCsvUploadModalOpen}
      />
    );
  }

  return {
    handleSaveNewContact,
    activeContact,
    setActiveContact,
    showDropdownFilter,
    setShowDropdownFilter,
    editing,
    setEditing,
    addContactModalOpen,
    setAddContactModalOpen,
    newContactData,
    setNewContactData,
    csvUploadModalOpen,
    setCsvUploadModalOpen,
    renderCsvModal,
  };
}
