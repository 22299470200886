import { VALID_CSV_REGEX, VALUE_REGEX } from "./constants";
import { QbDBTokenResult } from "./types";
import {
  ContactsMapping,
  IGNORE_COLUMN,
} from "../CsvHeaderDropdowns/constants";
import { api } from "../../../../helpers/topline-api";
import { IHandleSaveNewContactReturn } from "../ContactsPage/types";

/**
 * Extract unique header keys from an array of CSV data.
 *
 * @param {Array} csvDataRowArray - An array of objects representing rows from the CSV.
 * @returns {Array} - An array of unique header keys.
 */
export const extractUniqueHeaderKeys = (csvDataRowArray: any[]) => {
  if (!Array.isArray(csvDataRowArray)) return [];

  const unifiedObject = Object.assign({}, ...csvDataRowArray);
  const keys = Object.keys(unifiedObject);

  return keys.filter((key) => key !== "" && key !== "\r");
};

export async function getQBUrl() {
  // If the calls don't depend on each other, execute them concurrently
  const deleteInfoResponse = api.get(`/api/delete_qb_info_in_db`);
  const getAuthCodeResponse = api.get(`/api/get-quickbook-authcode`);

  // Wait for both requests to complete
  await deleteInfoResponse;
  const response = await getAuthCodeResponse;

  return response;
}
export class CSVParser {
  static extractHeader(csvString: string): string[] {
    return csvString.slice(0, csvString.indexOf("\n")).split(",");
  }

  static extractRows(csvString: string): string[] {
    return csvString.slice(csvString.indexOf("\n") + 1).split("\n");
  }

  private static matchCSVValues(text: string): string[] | null {
    if (!VALID_CSV_REGEX.test(text)) return null;
    const values: string[] = [];

    text.replace(VALUE_REGEX, (match, singleQuoted, doubleQuoted, unquoted) => {
      if (singleQuoted !== undefined)
        values.push(singleQuoted.replace(/\\'/g, "'"));
      else if (doubleQuoted !== undefined)
        values.push(doubleQuoted.replace(/\\"/g, '"'));
      else if (unquoted !== undefined) values.push(unquoted);
      return "";
    });

    if (/,\s*$/.test(text)) values.push("");
    return values;
  }

  static parseRow(row: string, headers: string[]): Record<string, string> {
    const values = CSVParser.matchCSVValues(row);
    console.log("what are values", values);
    if (!values) return {};

    return headers.reduce((acc, header, idx) => {
      acc[header] = values[idx] || "";
      return acc;
    }, {} as Record<string, string>);
  }

  static csvFileToArray(csvString: string): Record<string, string>[] {
    const headers = CSVParser.extractHeader(csvString);
    const rows = CSVParser.extractRows(csvString);
    return rows.map((row, index) => {
      return CSVParser.parseRow(row, headers);
    });
  }
}

export const getQBTokenFromDb = async (): Promise<QbDBTokenResult> => {
  const resObj = await api.get(`/api/get-qb-refresh-token`);
  const isQuickBookRefreshTokenInDb = resObj?.data.message.data;
  const today = new Date();
  const qbExpirationDate =
    new Date(resObj?.data.message.expiration_date) || today;
  const isQbRefreshTokenExpired = today.getTime() > qbExpirationDate.getTime();
  return { isQuickBookRefreshTokenInDb, isQbRefreshTokenExpired };
};

export function getNumberOfIgnoredColumn(dataMap: any) {
  const allKeys = Object.keys(dataMap);

  let ignoredColNames = "";
  let ignoredColNum = 0;
  let toolTipText = "";
  for (const key of allKeys) {
    if (dataMap[key] === IGNORE_COLUMN) {
      ignoredColNum += 1;
      ignoredColNames =
        ignoredColNames +
        `${Object.keys(ContactsMapping).find(
          (col) => ContactsMapping[col] === key
        )}\n`;
    }
  }

  if (ignoredColNum > 1) toolTipText = `${ignoredColNum} columns ignored`;
  else toolTipText = `${ignoredColNum} column ignored`;
  return { toolTipText, ignoredColNames };
}

export async function importGoogleContacts() {
  try {
    const resObj = await api.get(`/api/google-contacts`);
    const { data, google_contact_counter, deleted_contact_count } =
      resObj.data.message;
    return { data, google_contact_counter, deleted_contact_count };
  } catch (error) {
    console.log("error importing google contacts", error);
    throw error;
  }
}

export async function uploadVCardFile(
  file: File
): Promise<IHandleSaveNewContactReturn | string> {
  try {
    const response = await api.postForm(`api/contacts-vcard`, {
      vcard: file,
    });

    const newContactList = response.data.message.contact_list;
    const emptyContactCount = response.data.message.empty_contact_count;
    return {
      imported: newContactList.length,
      duplicated: 0,
    };
  } catch (e) {
    console.log("error:", e);
    throw e;
  }
}
