import router from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { DesktopContext } from "../../../../contexts/DesktopContextProvider/DesktopContextProvider";
import { importContactsToast } from "../../../../hooks/utils";
import UtilityModal from "../../../common/UtilityModal/UtilityModal";
import Alert from "../../../uiwrappers/Alert/Alert";
import ContactModalFileUpload from "../ContactModalFileUpload/ContactModalFileUpload";
import CsvHeaderDropdowns from "../CsvHeaderDropdowns/CsvHeaderDropdowns";
import fetchAllContacts from "../helpers/fetchAllContacts";
import { getQbContacts } from "../helpers/getQbContacts";
import { GOOGLE_CONTACTS, QUICKBOOKS } from "./constants";
import {
  CSVParser,
  extractUniqueHeaderKeys,
  getNumberOfIgnoredColumn,
  getQBTokenFromDb,
  getQBUrl,
  uploadVCardFile,
} from "./utils";
import { IContact } from "../Contact/types";
import { IHandleSaveNewContactReturn } from "../ContactsPage/types";
import {
  ContactsMapping,
  IGNORE_COLUMN,
} from "../CsvHeaderDropdowns/constants";
import { InformationCircleIcon } from "@heroicons/react/outline";
import AnotherSource from "../AnotherSource/AnotherSource";
import { useAuthGoogleContacts } from "../../../../hooks/useAuthGoogleContacts";
import Papa from "papaparse";
import { useContacts } from "../../../../hooks/useContacts/useContacts";

interface CsvUploadModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  importModalOpen?: boolean;
  setReviewRequestModalOpen?: any;
}

export default function CsvUploadModal({
  open,
  setOpen,
  importModalOpen,
  setReviewRequestModalOpen,
}: CsvUploadModalProps) {
  const { profileInfo, setContacts, setIsQuickbooksAuthenticated } =
    useContext(DesktopContext);
  const { slug } = profileInfo || {};
  const { handleSaveNewContact } = useContacts();

  const [anotherSource, setAnotherSource] = useState("");
  const [csvTitle, setCsvTitle] = useState<string | null>();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [columnsToUseForImport, setColumnsToUseForImport] = useState<string[]>(
    []
  );
  const [dataForImport, setDataForImport] = useState<any>([]);
  const [isNextButtonDisable, setIsNextButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataMap, setDataMap] = useState({});

  const [alert, setAlert] = useState("");
  const { startGoogleContactsLogin } = useAuthGoogleContacts(slug);

  useEffect(() => {
    if (csvTitle || anotherSource) {
      setAlert("");
    }
  }, [csvTitle, anotherSource]);

  const toplineProVars = Object.values(ContactsMapping);

  useEffect(() => {
    const newData = csvData.map((contact, idx) => {
      const item: any = {};
      const headerKeyArr = Object.values(dataMap);
      for (let i = 0; i < headerKeyArr.length; i++) {
        if (headerKeyArr[i] === IGNORE_COLUMN) continue;
        item[toplineProVars[i]] = contact[headerKeyArr[i]];
      }

      return item;
    });

    setDataForImport(newData);
  }, [dataMap]);
  useEffect(() => {
    const headers = extractUniqueHeaderKeys(csvData);
    setColumnsToUseForImport([...headers, IGNORE_COLUMN]);
  }, [csvData, setColumnsToUseForImport]);

  useEffect(() => {
    if (anotherSource || file) {
      setIsNextButtonDisable(false);
    } else {
      setIsNextButtonDisable(true);
    }
  }, [file, anotherSource]);

  async function getQBToken() {
    const response = await getQBUrl();
    const url = `${response?.data.message.data}`;
    router.push(url);
    return response;
  }

  const handleStateAfterAttemptingToImportContact = (
    newQbContactsObj: any,
    deleteCounter: any,
    importedContactCounter?: any
  ) => {
    const { newQbContacts } = newQbContactsObj;
    if (newQbContactsObj?.error) {
      importContactsToast("failure");
    } else {
      importContactsToast({
        from: "quickbooks",
        imported: newQbContacts.length,
        deleteCounter: deleteCounter,
        importedContactCounter: importedContactCounter,
        showDeleteCounter: true,
      });
    }
    setLoading(false);
    setContacts(newQbContacts);
    setOpen(false);
    setAnotherSource("");
    setIsQuickbooksAuthenticated(true);
  };

  const handleOnSubmit = async () => {
    if (file) {
      if (file.name.includes(".vcf")) {
        // upload vcf file to the server
        try {
          const response = await uploadVCardFile(file);
          await updateUIAfterSuccessfulImport(response);
        } catch (error) {
          importContactsToast("failure");
        } finally {
          if (importModalOpen) setReviewRequestModalOpen(true);

          handleClose();
        }
      } else {
        await handleFileSubmission();
      }
    } else {
      await handleApiBasedSubmission();
    }
  };

  const handleFileSubmission = async () => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const csvOutput = event?.target?.result;

        // Use PapaParse to parse the CSV data more reliably
        Papa.parse(csvOutput, {
          header: true, // Assuming the first row contains column headers
          dynamicTyping: false, // Attempt to automatically convert values to numbers, etc.
          skipEmptyLines: true, // Skip empty lines
          transform: (value) => {
            // Remove the equals sign from values like ="88312"
            return value.startsWith('="')
              ? value.slice(2, value.length - 1)
              : value;
          },
          complete: (result) => {
            setCsvData(result.data);
          },
          error: (error) => {
            console.error("Error parsing CSV using PapaParse:", error.message);
            try {
              const csvData = CSVParser.csvFileToArray(csvOutput as string);
              setCsvData(csvData);
            } catch (error) {
              console.log("Error parsing manually", error);
              importContactsToast("failure");
              setOpen(false);
            }
          },
        });
      };

      fileReader.readAsText(file);
    }
  };

  const handleApiBasedSubmission = async () => {
    const { isQuickBookRefreshTokenInDb, isQbRefreshTokenExpired } =
      await getQBTokenFromDb();

    if (importModalOpen) {
      await handleImportForReview(
        isQuickBookRefreshTokenInDb,
        isQbRefreshTokenExpired
      );
    } else {
      await handleImportForContacts(
        isQuickBookRefreshTokenInDb,
        isQbRefreshTokenExpired
      );
    }
  };

  const handleImportForReview = async (
    isTokenInDb: boolean,
    isTokenExpired: boolean
  ) => {
    localStorage.setItem("import_from_qb", "true");
    if (isTokenInDb && !isTokenExpired) {
      await importFromQuickBookForReview();
    } else {
      await getQBToken();
    }
  };

  const handleImportForContacts = async (
    isTokenInDb: boolean,
    isTokenExpired: boolean
  ) => {
    if (isTokenInDb && !isTokenExpired) {
      await importFromQuickBookForContacts();
    } else {
      await getQBToken();
    }
  };

  const importFromQuickBookForReview = async () => {
    const newQbContactsObj = await getQbContacts(
      "reviews",
      slug || "",
      router,
      ""
    );

    const { newQbContacts, deleteCounter, importedContactCounter } =
      newQbContactsObj;
    handleStateAfterAttemptingToImportContact(
      newQbContactsObj,
      deleteCounter,
      importedContactCounter
    );
  };

  const importFromQuickBookForContacts = async () => {
    try {
      const newQbContactsObj = await getQbContacts(
        "contacts",
        slug || "",
        router,
        ""
      );

      if (newQbContactsObj?.error) {
        await getQBToken();
        return;
      }

      const { newQbContacts, deleteCounter, importedContactCounter } =
        newQbContactsObj;
      handleStateAfterAttemptingToImportContact(
        newQbContactsObj,
        deleteCounter,
        importedContactCounter
      );
    } catch (error) {
      console.log("error getting qb contact", error);
    }
  };

  async function handleImport() {
    try {
      setLoading(true);
      const importResponse = await importContacts(dataForImport);

      if (importResponse !== "error") {
        await updateUIAfterSuccessfulImport(importResponse);
      } else {
        importContactsToast("failure");
      }

      if (importModalOpen) setReviewRequestModalOpen(true);

      handleClose();
    } catch (error) {
      console.error("Error importing contacts:", error);
    } finally {
      setLoading(false);
    }
  }

  async function importContacts(data: IContact[]) {
    try {
      const response = await handleSaveNewContact(data);
      return response;
    } catch (error) {
      console.error("Error saving new contacts:", error);
      return "error";
    }
  }

  async function updateUIAfterSuccessfulImport(
    response: IHandleSaveNewContactReturn
  ) {
    const contacts = await fetchAllContacts();
    setContacts(contacts);

    if (response?.imported && response?.duplicated) {
      importContactsToast({
        status: "success",
        imported: response.imported,
        duplicated: response.duplicated,
      });
    } else {
      importContactsToast("success");
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitClickHandler = () => {
    if (dataForImport.length) {
      handleImport();
    } else if (anotherSource === QUICKBOOKS) {
      setLoading(true);
      handleOnSubmit();
    } else if (anotherSource === GOOGLE_CONTACTS) {
      startGoogleContactsLogin();
      handleClose();
    } else if (csvTitle) {
      handleOnSubmit();
    } else {
      setAlert("Please upload a .csv file or select another source.");
    }
  };

  const elementInlineWithButtons = (
    <div className="flex items-center space-x-2 mt-2 w-full sm:w-44">
      {csvData?.length > 0 && (
        <>
          <div className="text-sm-medium sm:text-gray-900 text-gray-500">
            {getNumberOfIgnoredColumn(dataMap).toolTipText}
          </div>
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              getNumberOfIgnoredColumn(dataMap).ignoredColNames
            }
            data-tooltip-place="top"
          >
            <InformationCircleIcon className="flex w-5 h-5 text-gray-500 cursor-pointer" />
          </a>
        </>
      )}
    </div>
  );

  return (
    <UtilityModal
      isOpen={open}
      setIsOpen={handleClose}
      onCancelHandler={handleClose}
      onSubmitHandler={onSubmitClickHandler}
      title="Upload contacts"
      width="sm:w-800"
      loading={loading}
      primaryButtonLoading={loading}
      showLoader={true}
      showCancelButton={true}
      showSubmitButton={true}
      isSubmitDisabled={isNextButtonDisable}
      submitButtonText={dataForImport.length ? "Upload" : "Next"}
      submitButtonId={dataForImport.length ? "upload-csv" : "next-csv"}
      footerBorder={true}
      headerBorder={csvData.length ? true : false}
      elementInlineWithButtons={elementInlineWithButtons}
    >
      <>
        <div className="flex flex-col sm:max-h-600 mt-3 text-center sm:mt-0 sm:text-left">
          {csvData.length > 0 && (
            <CsvHeaderDropdowns
              headerKeys={columnsToUseForImport}
              array={csvData}
              csvTitle={csvTitle}
              dataMap={dataMap}
              setDataMap={setDataMap}
            />
          )}
          {csvData?.length == 0 && (
            <div>
              {
                <ContactModalFileUpload
                  csvTitle={csvTitle}
                  setCsvTitle={setCsvTitle}
                  setAlert={setAlert}
                  setFile={setFile}
                  setAnotherSource={setAnotherSource}
                />
              }
            </div>
          )}
        </div>
        {!csvTitle && (
          <AnotherSource
            anotherSource={anotherSource}
            setAnotherSource={setAnotherSource}
          />
        )}
        <Alert alertMessage={alert} customStyle="-mt-2 mb-2" />
      </>
    </UtilityModal>
  );
}
